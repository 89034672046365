import { createSlice } from "@reduxjs/toolkit";
import { createEncryptedAsyncThunk } from "../../Utility/APIUtils";

const initialState = {
  comSuccess: false,
  commonResponse: {
    error: false,
    message: "",
  },
  commonDeleteResponse: {
    status: false,
    message: "",
  },
  commonDeleteSucces: false,
  error: "",
  loading: false,
};

export const submitLeadGeneration = createEncryptedAsyncThunk(
  "OtherSlice/submitLeadGeneration",
  "account/LeadGeneration",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      is_mobile_linked_aadhar: true,
      current_address: {
        address: params.address,
        pinCode: params.body.pinCode,
        landMark: params.body.landMark,
      },
      merchantcode: params.loginData.primaryPhone,
      monthly_income: 0,
      income_proof: true,
      refid: "",
      name: params.body.userName,
      mobile_no: params.body.mobileNumber,
      state: params.body.state,
      customer_type: params.custType,
      email: params.body.emailId,
      leadType: params.leadType,
      serviceId: params.serviceId.serviceId,
    }),
  }
);
export const getLeadeGeneration = createEncryptedAsyncThunk(
  "OtherSlice/getLeadeGeneration",
  "account/GetLeadGeneration",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      txn_id: "",
      requestuserId: `${params.currentUser.userId}`,
      serviceId: params.serviceId.serviceId,
    }),
  }
);
export const getStaticData = createEncryptedAsyncThunk(
  "OtherSlice/getStaticData",
  "account/GetCustomerFieldStaticData",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.serviceId.serviceId,
      typeRequest: params.type,
    }),
  }
);
export const isUserRegistration = createEncryptedAsyncThunk(
  "OtherSlice/isUserRegistration",
  "account/IP_OutletRegistration",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.serviceId.serviceId,
      otpReference: "",
      otp: "",
      gender: params.selectGender,
      physicallyHandicapped: params.selectHandyCap,
      highestEducation: params.selectHighestEdu,
      castCategory: params.selectCast,
      operatingHoursFrom: params.hoursFrom,
      operatingHoursTo: params.hoursTo,
      deviceName: params.deviceName,
      connectivityType: params.connectType,
      connectionProvider: params.selectConnectProvide,
      weeklyOff: params.selectWeeklyOff,
      expectedAnnualTurnover:params.expAnuualIncome,
      expectedAnnualIncome:params.expAnuualIncome,
    }),
  }
);
export const isOtpVerificationUserRegistration = createEncryptedAsyncThunk(
  "OtherSlice/isUserRegistration",
  "account/IP_OutletRegistration",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.serviceId.serviceId,
      otpReference: params.otpReferanceId,
      otp: params.otp,
      gender: params.selectGender,
      physicallyHandicapped: params.selectHandyCap,
      highestEducation: params.selectHighestEdu,
      castCategory: params.selectCast,
      operatingHoursFrom:`09:00 AM`,
      operatingHoursTo: `06:00 PM`,
      deviceName: params.deviceName,
      connectivityType: params.connectType,
      connectionProvider: params.selectConnectProvide,
      weeklyOff: params.selectWeeklyOff,
      expectedAnnualTurnover: params.expAnuualTurnOver,
      expectedAnnualIncome: params.expAnuualIncome,
    }),
  }
);
export const OtherSlice = createSlice({
  name: "OtherSlice",
  initialState,
  reducers: {
    commonSuccess(state) {
      return { ...state, commonResponse: true };
    },
    commonFailed(state, action) {
      return {
        ...state,
        commonResponse: { error: true, message: action.payload },
      };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return {
        ...state,
        rechargeResponse: { status: true, message: action.payload },
      };
    },
    commDeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    commDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    commDeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: "" },
      };
    },
  },
});

export const {
  commonSuccess,
  commonFailed,
  FetchSuccess,
  FetchFailed,
  commDeleteSuccess,
  commDeleteFailed,
  commDeleteInitial,
} = OtherSlice.actions;
export default OtherSlice.reducer;
