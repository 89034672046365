import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import Header from './header/Header';
// import Customizer from './customizer/Customizer';
import Sidebar from './sidebars/vertical/Sidebar';
import HorizontalHeader from './header/HorizontalHeader';
import HorizontalSidebar from './sidebars/horizontal/HorizontalSidebar';
import AppLogout from '../components/common/AppLogout';

const DistributorLayout = () => {
  // const customizerToggle = useSelector((state) => state.customizer.customizerSidebar);
  const toggleMiniSidebar = useSelector((state) => state.customizer.isMiniSidebar);
  const showMobileSidebar = useSelector((state) => state.customizer.isMobileSidebar);
  const topbarFixed = useSelector((state) => state.customizer.isTopbarFixed);
  const LayoutHorizontal = useSelector((state) => state.customizer.isLayoutHorizontal);
  const isFixedSidebar = useSelector((state) => state.customizer.isSidebarFixed);

  return (
    <main className="super-dis-layout">   
    <AppLogout>
        {/********header**********/}
      {LayoutHorizontal ? <HorizontalHeader /> : <Header />}
      {LayoutHorizontal ? <HorizontalSidebar /> : ""}
      {/********Sidebar Content**********/}
      <div className={`pageWrapper d-md-block d-lg-flex ${toggleMiniSidebar ? "isMiniSidebar" : ""}`}>
        {LayoutHorizontal ? (
          ""
        ) : (
          <aside className={`sidebarArea ${showMobileSidebar ? "showSidebar" : ""}`}>
            <Sidebar />
          </aside>
        )}
        {/********Main Content**********/}
        <div className={`contentArea ${topbarFixed ? "fixedTopbar" : ""} vertualWithRight`}>
          <Container fluid className="p-3 boxContainer">
            <div className={isFixedSidebar && LayoutHorizontal ? "HsidebarFixed" : ""}>
              <Outlet />
            </div>
          </Container>
        </div>
      </div>
    </AppLogout>
  </main>
  );
};

export default DistributorLayout;
